.carousel .slider-wrapper.axis-vertical,
.carousel .slider-wrapper.axis-vertical .slider,
.carousel
  .slider-wrapper.axis-vertical
  .slider
  li
  div[class*="makeStyles-gameSliderImageContent"],
.carousel .slider-wrapper.axis-vertical .slider li div[style*="height"] {
  height: 100vh !important;
}
