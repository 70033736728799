.wallect-connect-login_cardBody #accessWalletBtn {
  background: #000;
  color: #fff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 6px 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}
.wallect-connect-login_cardBody #accessWalletBtn svg {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  margin: 0 10px 0 0 !important;
}
.wallect-connect-login_cardBody #accessWalletBtn svg image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.wallect-connect-login_cardBody #accessWalletBtn svg use {
  transform: scale(1);
}
