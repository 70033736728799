body {
  background: #000000 !important;
}

App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.bg_container {
  max-width: 480px;
  margin: 0 auto 0;
  max-height: 100vh;
  overflow: hidden;
}

.bg_game_video,
.bg_game_iframe {
  width: 100%;
  min-height: 93vh;
  max-height: 93vh;
  overflow: hidden;
}

.bg_game_iframe {
  border: 0;
}

@font-face {
  font-family: canaroMedium;
  src: url("./assets/fonts/CanaroW00-Medium.ttf");
}
@font-face {
  font-family: canaroBook;
  src: url("./assets/fonts/CanaroW00-Book.ttf");
}
.pages-container {
  width: 350px;
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .pages-container {
    width: 100%;
    height: 100%;
  }
}

.button_maiar {
  padding: 0.5rem 2rem!important;
  width: 200px !important;
  color: black !important;
  margin-top: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  height: 40px !important;
  border-radius: 25px !important;
  background-color: white !important;
}

.modal {
  z-index: 999999 !important;
}
.modal button {
  -webkit-appearance: button;
}

iframe[style*="position"] {
  display: none;
}


